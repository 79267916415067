import { Component, OnInit ,Input} from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {
  deferLoad : Boolean = false;
  @Input() data: any = [];
  constructor(private scroll :ScrollToService) { }

  ngOnInit() {
  }

  getCategory(item) {
    let type;
    //0 : public,1: youth,2:rythu 3:sevadal 4 women
    switch (item) {
      case 0:
        type = "public";
        break;
      case 1:
        type = "youth";
        break;
      case 2:
        type = "rythu";
        break;
      case 3:
        type = "sevadal";
        break;
      case 4:
        type = "women";
        break;
    }
    return type;
  }

  getImagePath(path) {
    // if(path == undefined || path == null){
      return "../../static/jspwebsitemain/images/andhrapradesh.jpg";
    // }else{
    //   return "./assets/images/"+path.toLowerCase()+".jpg";
    // }

  }

  getCurrentEvents(data){
    if(data.length > 0){
      let today = new Date().getTime();
      let eventDate = new Date(data[0]['EventOn']).getTime();
      if(eventDate >= today){ 
          return true;
      }
    }
    return false;
  }

  scrollPosition(position){
    let offset_value;
    if(position == 'footer_wrapper') {
      offset_value = -140;
    } else {
      offset_value = -50;
    }
    const config: ScrollToConfigOptions = {
      target: position,
      duration: 1,
      offset: offset_value,
      //easing: 'easeOutElastic',
    };
    this.scroll.scrollTo(config);
  }
}
