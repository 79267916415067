import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss']
})
export class TeaserComponent implements OnInit {
  deferLoad : Boolean = false;
  constructor() { }

  ngOnInit() {
  }
  openLink(url){
    window.open(url,'_blank');
  }
}
