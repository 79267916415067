import { Component, OnInit,Output,EventEmitter, Inject} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT,  } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output()
  scrollTo : EventEmitter<number> = new EventEmitter<number>();
  @Output()
  langChange : EventEmitter<string> = new EventEmitter<string>();
  fixedbar: boolean = false;
  constructor(@Inject(DOCUMENT) document,private translate :TranslateService) { }
  mobilemenu:boolean=false;
  languagebar:boolean=false;
  languagetext: any = "తెలుగు";
  ngOnInit() {
    this.addLangClass();
  }

  getLangName(lang){
    if(lang == "en"){
      return "english";
    }else{
      return "telugu" ;
    }

  }
  addLangClass(){
    var langcode;
    let element = document.getElementById('JSP_WEB');
    element.classList.remove('english');
    element.classList.remove('telugu');
    let lang = localStorage.getItem("jslang");
    if(lang == undefined || lang == null){
        this.languagetext = "తెలుగు";
         langcode = "telugu";
    }else{
      if(this.getLangName(lang) == "telugu"){
        this.languagetext = "తెలుగు";
         langcode = "telugu"; 
      } else {
        this.languagetext = "English";
        langcode = "english"; 
      }
    }
    element.classList.add(langcode);
  }
  openmenu(){
    if(window.innerWidth < 1100) {
    this.mobilemenu = !this.mobilemenu;
    if(this.mobilemenu){
      
      let element = document.getElementById('JSP_WEB');
       element.classList.add('menuopened');
    } else {
      let element = document.getElementById('JSP_WEB');
       element.classList.remove('menuopened');
    }
  }
  }
  openlang(){
    this.languagebar = !this.languagebar;
  }
  onClickedOutside(e: Event) {
    this.languagebar = false;
  }
  jumpTo(position){
    this.scrollTo.emit(position);
  }

  selectLang(lang, text){
    this.translate.use(lang);
    this.languagetext = text;
    let prevoiusLang = localStorage.getItem("jslang");
    localStorage.setItem("jslang",lang);
    this.langChange.emit(lang);
    let element = document.getElementById('JSP_WEB');
    element.classList.remove(this.getLangName(prevoiusLang));
    element.classList.add(this.getLangName(lang));
  }


}
