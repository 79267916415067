import { Component, OnInit,Input} from '@angular/core';
import { Gallery, GalleryItem, ImageItem, GalleryConfig } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  @Input() data: any = [];
  data1 : any = [];
  deferLoad : Boolean = false;
  constructor(public gallery: Gallery, private lightbox: Lightbox) { }
  galleryId = 'myLightbox';
  items: GalleryItem[];
  ngOnInit() {
   
  }
  ngOnChanges(){
   this.data.map(item => {
      item.show = false;
    });
  }
  getthumb(url){
    var stripburl = url.substring(0, url.length-4);
    // var tumburl = stripburl+'_thumb.jpg';
    var tumburl = url;
    return tumburl;
  }
  
  // galleryRef.setConfig(config)
  openlightbox(item){

  const config: GalleryConfig = {
    thumb: true,
    counter: true
  };
    this.items = [];
    this.gallery.ref('lightbox').setConfig(config)
    this.gallery.config.autoPlay = false;
    
    this.items = item.map(item => {
      return new ImageItem({ src: item.image, thumb: item.thumb });
    });
    this.gallery.ref('lightbox').load(this.items);
    this.lightbox.setConfig({
      panelClass: 'fullscreen'
    })
    this.lightbox.open();
  }

}
