import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Route } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './header/header.component';
import { BannerComponent } from './banner/banner.component';
import { MissionComponent } from './mission/mission.component';
import { NewsContainerComponent } from './news-container/news-container.component';
import { NewsComponent } from './news/news.component';
import { EventsComponent } from './events/events.component';


import { PrinciplesComponent } from './principles/principles.component';
import { VisionComponent } from './vision/vision.component';
import { TeaserComponent } from './teaser/teaser.component';
import { HttpService } from './service/http.service';
import { GalleryModule } from '@ngx-gallery/core';
import { LightboxModule } from '@ngx-gallery/lightbox';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { ClickOutsideModule } from 'ng4-click-outside';


// import { CarouselModule,ModalModule } from 'ngx-bootstrap';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { GalleryComponent } from './gallery/gallery.component';
import { VideosComponent } from './videos/videos.component';
import { PressComponent } from './press/press.component';
import { DigitalComponent } from './digital/digital.component';
import { FooterComponent } from './footer/footer.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { LazyLoadModule } from 'ng-lazy-load';
import { AppRoutingModule } from './app-routing.module';
import { JanavaniComponent } from './janavani/janavani.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  // return new TranslateHttpLoader(httpClient, "./static/jspwebsitemain/locale/lang-", ".js");
return new TranslateHttpLoader(httpClient, "https://janasenaparty.org/static/jspwebsitemain/locale/lang-", ".js");
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    BannerComponent,
    MissionComponent,
    NewsContainerComponent,
    NewsComponent,
    EventsComponent,
    PrinciplesComponent,
    VisionComponent,
    TeaserComponent,
    GalleryComponent,
    VideosComponent,
    PressComponent,
    DigitalComponent,
    FooterComponent,
JanavaniComponent
  ],
  imports: [
    BrowserModule,
    ClickOutsideModule,
    BrowserAnimationsModule,
    HttpModule,
    FormsModule,
    DeferLoadModule,
    LazyLoadModule,
    RecaptchaModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ScrollToModule.forRoot(),
    GalleryModule.forRoot(),
    LightboxModule.forRoot(),
    PinchZoomModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      progressBar: true,
      closeButton: true,
      progressAnimation: 'increasing',
    }),
    AppRoutingModule,
    RouterModule
  ],
  providers: [HttpService, TranslateService],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
