import { Component, OnInit,Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
  @Input() data: any = [];
  constructor(private translate :TranslateService) { }

  ngOnInit() {
  }
  ngOnChanges() {
  }

  gotoNews(id){
    window.open(`https://news.janasenaparty.org/news-detail/${btoa(id)}`, '_blank');
  }

}
