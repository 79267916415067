import { Component, Input, OnInit,HostListener,ElementRef,Output,EventEmitter} from '@angular/core';
import {HttpService} from '../service/http.service';
import { ToastrService } from 'ngx-toastr';

// this.toastr.success('Education Updated Successfully');
// this.toastr.warning('Education Deleted Successfully');
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Output()
  notifyMe : EventEmitter<number> = new EventEmitter<number>();
  emailid:any = "";
  buttonDisabled:boolean;
  recaptcha : Boolean = false;
  constructor(private httpService :HttpService, private toastr: ToastrService) { }
  notify(val){
    this.notifyMe.emit(val);
  }
  ngOnInit() {
  }

  isValid(){
    if(this.emailid.length > 0  && this.recaptcha){
      return true;
    }
    return false;
  }

  resolved(captchaResponse: string) {
    if(captchaResponse){
      this.recaptcha = true;
    }
}
  getStatus(){
    if(localStorage.getItem('jspushsubscribe') == '1'){
      this.buttonDisabled = true;
      return true;
    }
    this.buttonDisabled = false;
    return false;
  }
  emailsub(){
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(this.emailid) == false || this.emailid == '') {
      this.toastr.warning('Please enter valid email ID.');
    } else {
      this.httpService.emailsub(this.emailid).subscribe(res =>{
        let response = JSON.parse(res);
        if(response.status == "0") {
          this.toastr.success('This email ID already subscribed, Thankyou.');
        } else if(response.status == "1") {
          this.toastr.success('Thankyou, email ID subscribed');
        }
        this.emailid = '';
      })
    }
  }
}
