import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-janavani',
  template: `<div class="iframe-container">
  <iframe
    [src]="iframeUrl"
    width="100%"
    height="100%"
    frameborder="0"
    allowfullscreen>
  </iframe>
</div>`,
  styleUrls: ['./janavani.component.scss'],

})
export class JanavaniComponent {
  iframeUrl: any='';

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    const localUrl = 'http://localhost:4201'; // Local development URL
    // const hostedUrl = 'https://your-angular18-app-domain.com'; // Replace with your hosted URL when available
alert(this.iframeUrl)
    // Determine the URL based on the environment
    // if (environment.production) {
    //   this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(hostedUrl);
    // } else {
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(localUrl);
    // }
  }
 }
