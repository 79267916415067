import { Component, OnInit ,Input} from '@angular/core';

@Component({
  selector: 'app-news-container',
  templateUrl: './news-container.component.html',
  styleUrls: ['./news-container.component.scss']
})
export class NewsContainerComponent implements OnInit {
  @Input() data: any = [];
  @Input() eventdata : any = [];
  constructor() { }

  ngOnInit() {
  }

}
