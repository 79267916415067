import { Component,ElementRef, HostListener, Inject,ViewEncapsulation } from '@angular/core';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';
import {HttpService} from './service/http.service';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class AppComponent {
  title = 'JSWebiste';
  subsrcibe: Boolean = false;
  isSubscribed: any = false;
  swRegistration = null;
  newsData : any = [];
  galleryData : any = [];
  videoData : any = [];
  pressData : any = [];
  eventData : any = [];
  isSession: any;
  noInternet : Boolean = false;
  lang : any;
  requestObj: any = { "States": [2, 3], "Districts": [], "Constituencies": [], "Parliaments": [], "EventCategories": [], "EventsFrom": null, "EventsTo": null };
  constructor(@Inject(DOCUMENT) document, private _scrollToService: ScrollToService,public el: ElementRef,private httpService :HttpService,private translateService :TranslateService){
    this.translateService.addLangs(["en", "tel"]);
    this.translateService.setDefaultLang('tel');
    let lang = localStorage.getItem("jslang");
    if(lang == undefined || lang == null){
      translateService.use('tel');
    }else{
      translateService.use(lang);
    }
    // this.httpService.getValidPublicKey().subscribe(data => {
    // });
    const deviceId = localStorage.getItem('jsDeviceId');
    if (deviceId) {
      this.checkDeviceExpiryStatus();
    }
    var version = this.detectIE();
    if (version === false) {
      this.initialiseServiceWorker();
    } else if (version >= 17) {
      this.initialiseServiceWorker();
    } else {
      console.log("Push notifications not supported for this browsser.");
    }

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 80) {
       let element = document.getElementById('JSP_WEB');
       element.classList.add('scrolled');
     } else {
      let element = document.getElementById('JSP_WEB');
        element.classList.remove('scrolled');
     }
  }


  opennotify(val){
    sessionStorage.clear();
    this.isSubscribed = true;
    this.isSession = false;
    // var version = this.detectIE();
    // if (version === false) {
    //   this.subscribe();
    // } else if (version >= 12) {
    //   this.subscribe();
    // } else {
    //   alert("Push notifications not supported for this browsser.");
    // }
  }
    scrollPosition(position){
    let offset_value;
    if(position == 'footer_wrapper') {
      offset_value = -140;
    } else {
      offset_value = -50;
    }
   if(window.innerWidth < 767){
    const elementList = document.querySelectorAll('#' + position);
    const element = elementList[0] as HTMLElement;
    element.scrollIntoView({block: 'start' });
    window.scrollBy(0, offset_value);
   }else{
    const config: ScrollToConfigOptions = {
      target: position,
      duration: 1000,
      offset: offset_value,
    };
    this._scrollToService.scrollTo(config);
   }
  }
  ngOnInit(){
  }
  langChanged(val){
    this.lang = val;

  }

  ngAfterViewInit(){
    //   this.httpService.requestDataFromMultipleSources().subscribe(res => {
    //   let status = JSON.parse(res).status;
    //   if(status == "success"){
    //     let result = JSON.parse(res).message;
    //     this.newsData = JSON.parse(result['News']);
    //     this.galleryData = JSON.parse(result['Gallery']);
    //     this.videoData = JSON.parse(result['Videos']);
    //     this.pressData = JSON.parse(result['PressReleases']);
    //     this.eventData = JSON.parse(result['Events']).EventCards;
    //   }else{
    //     this.noInternet = true;
    //   }
    // },err =>{
    //   this.noInternet =  true;
    // });
  }



  detectIE(){
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }
  var is_iPad = navigator.userAgent.match(/iPad|iPhone|iPod/i) != null;
  if(is_iPad) {
    return true;
  }
  return false;
  }

  checkDeviceExpiryStatus() {
    const deviceCheckDate = localStorage.getItem('jsNextDeviceCheckadate');
    const NextDeviceCheckadate = new Date(deviceCheckDate).getTime();
    let currentDate: any;
    currentDate = new Date().getTime();
    // currentDate.setDate(currentDate.getDate() + 10);
    const diffdays = (NextDeviceCheckadate - currentDate) / (1 * 24 * 60 * 60 * 1000);
    if (diffdays <= 0) {
      // Check DeviceId exists in 410 or not
      this.checkDeviceIdValidation();
    }
  }

  checkDeviceIdValidation() {
    const deviceId = localStorage.getItem('jsDeviceId');
    this.httpService.deviceIdValidation({ deviceId: deviceId, appKey: '5a982f9cde3418601925c57f' }).subscribe(data => {
      //console.log(' DeviceId verification success ', data);
      data = JSON.parse(data);
      if (data.status === 'success' && data.isDeviceIdExists === true) {
        localStorage.removeItem('jsDeviceId');
        localStorage.removeItem('jsNextDeviceCheckadate');
        this.unsubscribe(); // Because if u unsubscribe then only browser will subscribe again
        // this.initialiseServiceWorker(); // To subscribe
      }
    }, error => {
      //console.log(' DeviceId verification Error ', error);
    });

  }

  updateLocation() {
    let deviceId = localStorage.getItem('jsDeviceId');
    if (deviceId) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          let locationObj = {};
          locationObj['appKey'] = '5a982f9cde3418601925c57f';
          locationObj['DeviceID'] = deviceId;
          locationObj['longitude'] = position.coords.longitude;
          locationObj['latitude'] = position.coords.latitude;
          this.httpService.sendLocation(locationObj).subscribe(res => {

          }, err => {

          })
        });
      }

    }
  }



  public initialiseServiceWorker() {
   // console.log('first');
    navigator.serviceWorker.register('jspworker2659.js?v='+new Date().getTime())
          .then((reg) => {
      //  console.log('service worker pre installed---' + JSON.stringify(reg));
        this.handleSWRegistration(reg);
        //console.log('service worker installed')
      })
      .catch(err => console.error('Error', err));
  }

  handleSWRegistration(reg) {
    if (reg.installing) {
     // console.log('Service worker installing');
    } else if (reg.waiting) {
      //console.log('Service worker installed');
    } else if (reg.active) {
      //console.log('Service worker active');
    }

    this.swRegistration = reg;
   // console.log(this.swRegistration);
    this.initialiseState(reg);
  }

  initialiseState(reg) {
    // Are Notifications supported in the service worker?
   // console.log("initialiseState");
    if (!(reg.showNotification)) {
     // console.log('Notifications aren\'t supported on service workers.');
      return;
    }

    // Check if push messaging is supported
    if (!('PushManager' in window)) {
      //console.log('Push messaging isn\'t supported.');
      return;
    }

    // We need the service worker registration to check for a subscription
    navigator.serviceWorker.ready.then((reg) => {
      // Do we already have a push message subscription?
     // console.log('Not yet subscribed to Push');
      this.isSession = sessionStorage.getItem('jsisSession');
      console.log(this.isSession);
     // console.log('isSession---' + this.isSession);

      if (this.isSession == undefined || this.isSession == null) {
        this.isSession = false;
      }
      else {
        this.isSession = true;
      }
      console.log(this.isSession);
      if (!this.isSession) {
        // reg.pushManager.getSubscription()
        //   .then((subscription) => {
            // this.isSubscribed = false;
            let subscribed = localStorage.getItem("jspushsubscribe");
            if (subscribed != '1' ) {
              setTimeout(()=> {this.isSubscribed = true;}, 5000);

            }
         // })
          // .catch(function (err) {
          //   //console.log('Error during getSubscription()', err);
          // });
      }
    });
  }

  subscribe() {
    this.isSubscribed = false;
    navigator.serviceWorker.ready.then((reg) => {
      var subscribeParams = { userVisibleOnly: true };
      var applicationServerPublicKey = localStorage.getItem('jsapplicationServerPublicKey');
      //Setting the public key of our VAPID key pair.
      var applicationServerKey = this.urlB64ToUint8Array(applicationServerPublicKey);
      subscribeParams['applicationServerKey'] = applicationServerKey;
      reg.pushManager.subscribe(subscribeParams)
        .then((subscription) => {
          var endpoint = subscription.endpoint;
          var key = subscription.getKey('p256dh');
          var auth = subscription.getKey('auth');
          this.sendTokenToServer(endpoint, key, auth);
        })
        .catch(function (e) {
          // A problem occurred with the subscription.
          //console.log('Unable to subscribe to push.', e);
        });
    });
  }

  sendTokenToServer(endpoint, key, auth){
    this.httpService.sendSubscriptionToServer(endpoint, key, auth).subscribe(data => {
      var data = JSON.parse(data);
      if (data.statusCode == 201) {
        sessionStorage.setItem('jsisSession', '1');
        localStorage.setItem('jspushsubscribe', '1');
        this.isSubscribed = false;
        const cdate = new Date();
        const numofdays = 10;
        const expdate = JSON.stringify(cdate.setDate(cdate.getDate() + numofdays));
        localStorage.setItem('jsNextDeviceCheckadate', expdate);
        localStorage.setItem('jsDeviceId', endpoint);
      }
      this.updateLocation();
    },error=>{
    });
  }

  nothanks() {
    this.isSubscribed = false;
    sessionStorage.setItem('jsisSession', '0');
  }
  unsubscribe() {
    var endpoint = null;
    sessionStorage.setItem('jsisSession', '0');
      this.swRegistration.pushManager.getSubscription()
          .then((subscription) => {
            if (subscription) {
              endpoint = subscription.endpoint;
              return subscription.unsubscribe();
            }
          })
          .catch(function (error) {
          })
          .then(() => {
            this.httpService.removeSubscriptionFromServer(endpoint);
            localStorage.setItem('jspushsubscribe', '0');
            this.isSubscribed = false;
          });
  }

  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

}
