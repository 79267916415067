import { Component, OnInit,Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {
  @Input() data: any = [];
  deferLoad : Boolean = false;
  constructor(private renderer: Renderer2,) { }

  ngOnInit() {
  }
  ngOnChanges(){
    this.data.map(item => {
       item.show = false;
     });
   }
  selectedItem: any;
  listClick(event, i) {
    this.selectedItem = i;
    this.renderer.addClass(document.body, 'noscroll');
}
close_pop() {
  // alert('hi');
  this.selectedItem = 10000000000;
  this.renderer.removeClass(document.body, 'noscroll');
}

geImage(url){
  let tumburl = url;
  return tumburl;
}

}
