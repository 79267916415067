import { Component, OnInit,HostListener,ElementRef,Output,EventEmitter} from '@angular/core';
@Component({
  selector: 'app-mission',
  templateUrl: './mission.component.html',
  styleUrls: ['./mission.component.scss']
})
export class MissionComponent implements OnInit {
  @Output()
  scrollTo : EventEmitter<number> = new EventEmitter<number>();
  state = 'hide';
  video: any = 1;
  deferLoad : Boolean = false;
  constructor(private el :ElementRef) { }
  @HostListener('window:scroll', ['$event'])
    checkScroll() {
      const componentPosition = this.el.nativeElement.offsetTop
      const scrollPosition = window.pageYOffset + 400;
      if (scrollPosition > componentPosition) {
        this.state = 'show'
      } else {
        // this.state = 'hide'
      }

    }
  ngOnInit() {
  }
  fullvideo: any = 10000;
  jumpTo(position){
    console.log(position)
    this.scrollTo.emit(position);
  }
  getimg(url){
    let id = url.toString();
    let videoId = id.split('.be/')[1];
  }
  openVideo(val){
    this.fullvideo = val;
  }
  close_pop() {
    this.fullvideo = 1000;
  }
  openLink(url){
    window.open(url,'_blank');
  }

}
