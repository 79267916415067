import { Component, OnInit, TemplateRef, ViewChild, HostListener, Input } from '@angular/core';
import { Gallery, GalleryItem, ImageItem, GalleryConfig } from '@ngx-gallery/core';
import { TranslateService } from '@ngx-translate/core';

const config: GalleryConfig = {
  autoPlay: true
};

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  @ViewChild("galleryTemplate") galleryTemplate: TemplateRef<any>;
  @Input() lang: any;
  constructor(public gallery: Gallery, private translate: TranslateService) { };
  // this.gallery.ref('lightbox')
  // .setConfig(config)
  images: GalleryItem[];
  images4: any = [];
  // allimages: any;
  allimages: any = [
    // new ImageItem({ src: '../../static/jspwebsitemain/images/nriimg.jpg', thumb: '../../static/jspwebsitemain/images/nriimg.jpg', seq: 1 }),
    // new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-12.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-12.jpg', seq: 12 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-01.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-01.jpg', seq: 1 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-02.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-02.jpg', seq: 2 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-03.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-03.jpg', seq: 3 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-04.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-04.jpg', seq: 4 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-05.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-05.jpg', seq: 5 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-06.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-06.jpg', seq: 6 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-07.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-07.jpg', seq: 7 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-08.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-08.jpg', seq: 8 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-09.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-09.jpg', seq: 9 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-10.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-10.jpg', seq: 10 }),
  ]
  mallimages: any = [
    // new ImageItem({ src: '../../static/jspwebsitemain/images/nriimg.jpg', thumb: '../../static/jspwebsitemain/images/nriimg.jpg', seq: 1 }),
  //  new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-12-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-12-xs.jpg', seq: 12 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-01-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-01-xs.jpg', seq: 1}),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-02-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-02-xs.jpg', seq: 2 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-03-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-03-xs.jpg', seq: 3 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-04-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-04-xs.jpg', seq: 4 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-05-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-05-xs.jpg', seq: 5 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-06-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-06-xs.jpg', seq: 6 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-07-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-07-xs.jpg', seq: 7 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-08-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-08-xs.jpg', seq: 8 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-09-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-09-xs.jpg', seq: 9 }),
    new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-10-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-10-xs.jpg', seq: 10 }),

  ]
  public innerWidth: any;
  public innerHeight: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit() {
    // this.getBannerImages();
  }

  ngOnChanges() {
    this.getBannerImages();
  }

  getBannerImages() {
    this.images4 = [];
    this.images = [];
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    var number, dnum = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    let selectedLang = localStorage.getItem("jslang");
    console.log(selectedLang);
    if (this.innerHeight < 520 && this.innerWidth > 768) {
      if (selectedLang == 'en') {
        this.images4.push(new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-11-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-11-xs.jpg', seq: 11 }));
        this.images4.push(new ImageItem({ src: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs.jpg', thumb: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs.jpg', seq: 12 }));

      } else {
        this.images4.push(new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-11-xs_tel.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-11-xs_tel.jpg', seq: 11 }));
        this.images4.push(new ImageItem({ src: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs_tel.jpg', thumb: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs_tel.jpg', seq: 12 }));

      }
      for (var i = 1; i < 5; i++) {
        var countr = dnum[Math.floor(Math.random() * dnum.length)];
        var index = dnum.indexOf(countr);
        if (index > -1) {
          dnum.splice(index, 1);
        }
        this.images4.push(this.mallimages[countr]);
      }
    } else if (this.innerWidth < 768) {
      if (selectedLang == 'en') {
        this.images4.push(new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-11-xs.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-11-xs.jpg', seq: 11 }));
         this.images4.push(new ImageItem({ src: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs.jpg', thumb: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs.jpg', seq: 12 }));
      } else {
        this.images4.push(new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-11-xs_tel.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-11-xs_tel.jpg', seq: 11 }));
         this.images4.push(new ImageItem({ src: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs_tel.jpg', thumb: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12-xs_tel.jpg', seq: 12 }));
      }
      for (var i = 1; i < 5; i++) {
        var countr = dnum[Math.floor(Math.random() * dnum.length)];
        var index = dnum.indexOf(countr);
        if (index > -1) {
          dnum.splice(index, 1);
        }
        this.images4.push(this.mallimages[countr]);
      }
    } else {
      if (selectedLang == 'en') {
        this.images4.push(new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-11.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-11.jpg', seq: 11 }));
        this.images4.push(new ImageItem({ src: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12.jpg', thumb: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12.jpg', seq: 12 }));
      } else {
        this.images4.push(new ImageItem({ src: '../../static/jspwebsitemain/images/hero-image-11_tel.jpg', thumb: '../../static/jspwebsitemain/images/hero-image-11_tel.jpg', seq: 11 }));
        this.images4.push(new ImageItem({ src: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12_tel.jpg', thumb: 'https://janasenabackup.blob.core.windows.net/banners/hero-image-12_tel.jpg', seq: 12 }));
      }
      for (var i = 1; i < 5; i++) {
        var countr = dnum[Math.floor(Math.random() * dnum.length)];
        var index = dnum.indexOf(countr);
        if (index > -1) {
          dnum.splice(index, 1);
        }
        this.images4.push(this.allimages[countr]);
      }
    }
    this.gallery.config.loop = true;
    this.gallery.config.autoPlay = true;
    console.log(this.images4);
    this.images = this.images4;
  }
  getContent(id) {
    if (this.innerWidth < 700) {
      const key = `banner_mob_${id}`;
      let returnString
      this.translate.get(key).subscribe((text: string) => {
        returnString = text;
      });
      return returnString;
    } else {
      const key = `banner_text_${id}`;
      let returnString
      this.translate.get(key).subscribe((text: string) => {
        returnString = text;
      });
      return returnString;
    }
  }

}
