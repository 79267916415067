import { Component, OnInit,Input} from '@angular/core';
import { Gallery, GalleryItem,GalleryConfig } from '@ngx-gallery/core';
import { Lightbox } from '@ngx-gallery/lightbox';
@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {
  @Input() data: any = [];
  constructor(public vgallery: Gallery, private lightbox: Lightbox) { }
  galleryId = 'myLightbox';
  items: GalleryItem[];
  deferLoad : Boolean = false;
  ngOnInit() {
  }

  ngOnChanges(){
    this.data.map(item => {
       item.show = false;
     });
   }

  getVideoImage(url, i){
    let id = url.toString();
    let videoId = id.split('.be/')[1];
    if(i == 0){
      return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
    } else {
      return `https://img.youtube.com/vi/${videoId}/default.jpg`;
    }
  }
  openVideo(url){
    const config: GalleryConfig = {
      thumb: true,
      counter: true
    };
    this.vgallery.ref('lightbox').reset();
    this.vgallery.ref('lightbox').setConfig(config);
    this.lightbox.setConfig({
      panelClass: 'videobox'
    })
    this.items = [];
    let id = url.toString();
    let videoId = id.split('.be/')[1];
    const initialState = { video: `https://www.youtube.com/embed/${videoId}?rel=0` };
    this.vgallery.config.thumb = false;
    this.vgallery.ref('lightbox').addYoutube({
        src: videoId
      });
    this.lightbox.open();
  }
}